




































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ListQuery } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: false,
      unRead: false,
    };
  },
  computed: {
    ...mapState("notifications", ["list", "count", "query"]),
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .notificationsGetList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      dispatch.notificationsGetUnRead().then((res) => {
        this.unRead = res.count > 0;
      });
    },
    onRefresh() {
      this.getList({
        ...this.query,
        page_number: 1,
      });
    },
    onLoad() {
      this.getList({
        ...this.query,
        page_number: this.query.page_number + 1,
      });
    },
    onNotificationClick(id: string) {
      this.$router.push(this.$paths.notifications + "/" + id);
    },
    onReadAll() {
      dispatch.notificationsMark().then(this.onRefresh);
    },
  },
  created() {
    this.onRefresh();
  },
});
